<script setup lang="ts">
import {onBeforeMount, ref, watch} from 'vue'
import {debounce} from 'vue-debounce'
import SearchModal from './SearchModal.vue'
import FontAwesomeIcon from '../../ts/fontawesome-icons'
import ProductCard from "./ProductCard.vue";


const props = defineProps({
  searchEndpoint: {
    type: String,
  },
  searchResultsPage: {
    type: Number,
  },
  noResults: {
    type: String,
  },
})

let showModal = ref(false)
let loading = ref(false)
let searchInput : any = ref(null)
let query = ref('')
let products : any = ref([])
let popularGroups = ref([])
let popularTerms = ref([])
let relatedProductGroups = ref([])
let noResults = ref('')


async function search() {
  loading.value = true
  await fetch(`${props.searchEndpoint}?q=${query.value}&take=9`)
  .then(response => response.json())
  .then(data => {
    if (data.length > 0) {
      products.value = [...data]
    } else {
      noResults.value = props.noResults
    }
    loading.value = false
  })
  .catch(error => {
    console.error(error)
  })
}
function goToSearchPage() {
  if (query.value.length > 2) {
    window.location.href = `/Default.aspx?ID=${props.searchResultsPage}&q=${query.value}&take=22`
  }
}

let productTranslations = ref({})
async function getTranslations() {
  await fetch('/dwapi/translations/Mobler2018/da-DK')
  .then(response => response.json())
  .then(data => {
     productTranslations.value = {
       // Find the translations. Get the object based on its "Key" value, Then we need to get the Value from, if it's empty get the DefaultValue instead
	   translationProductJubileePrice: data.find((translation: { Key: string }) => translation.Key === 'ProductJubileePrice').Value || data.find((translation: { Key: string }) => translation.Key === 'ProductJubileePrice').DefaultValue,
       translationPriceBefore: data.find((translation: { Key: string }) => translation.Key === 'PriceBefore').Value || data.find((translation: { Key: string }) => translation.Key === 'PriceBefore').DefaultValue,
       translationPriceSaving: data.find((translation: { Key: string }) => translation.Key === 'PriceSaving').Value || data.find((translation: { Key: string }) => translation.Key === 'PriceSaving').DefaultValue,
       translationProductPriceFrom: data.find((translation: { Key: string }) => translation.Key === 'ProductPrice.From').Value || data.find((translation: { Key: string }) => translation.Key === 'ProductPrice.From').DefaultValue,
       translationSplashProductOffer: data.find((translation: { Key: string }) => translation.Key === 'ProductOffer').Value || data.find((translation: { Key: string }) => translation.Key === 'ProductOffer').DefaultValue,
       translationSplashNewItem: data.find((translation: { Key: string }) => translation.Key === 'ProductNew').Value || data.find((translation: { Key: string }) => translation.Key === 'ProductNew').DefaultValue,
       translationSplashLowPrice: data.find((translation: { Key: string }) => translation.Key === 'ProductLowPrice').Value || data.find((translation: { Key: string }) => translation.Key === 'ProductLowPrice').DefaultValue,
       translationSplashDailyPrice: data.find((translation: { Key: string }) => translation.Key === 'ProductDailyPriceSplash').Value || data.find((translation: { Key: string }) => translation.Key === 'ProductDailyPriceSplash').DefaultValue,
       translationSplashCombinationOffer: data.find((translation: { Key: string }) => translation.Key === 'ProductCombinationOffer').Value || data.find((translation: { Key: string }) => translation.Key === 'ProductCombinationOffer').DefaultValue,
    }
  })
}

// Calculate the top positioning of the modal,the modal should always be positioned so the search input is 20px inside from the top
function calculateModalPosition() {
  let searchInput = document.getElementById('js-search-bar')
  let modal = document.getElementById('js-search-modal')
  let searchInputPosition = searchInput.getBoundingClientRect()
  let modalTop = 0
  let searchTop;

  // Only if viewport width is above 768px
  if (window.innerWidth > 768) {
    modalTop = searchInputPosition.top - 20
  } else {
    // Move search input to the top of the viewport
    searchTop = searchInputPosition.top * -1 + 20
    searchInput.classList.add('tw-absolute')
  }

  // When search is closed, remove the absolute positioning
  if (!showModal.value) {
    searchInput.classList.remove('tw-absolute')
  }

  modal.style.top = `${modalTop}px`
  searchInput.style.top = `${searchTop}px`
}

async function getPopularGroups() {
  await fetch('/mobler-api/session/Relewise/PopularProductGroups')
  .then(response => response.json())
  .then(data => {
    popularGroups.value = data
  })
}

async function getPopularTerms() {
  await fetch('/mobler-api/session/Relewise/PopularSearchTerms?pageId=7177')
  .then(response => response.json())
  .then(data => {
    popularTerms.value = data
  })
}

async function getRelatedProductGroups(term: string, take: number = 10, skip: number = 0) {
  await fetch(`/mobler-api/session/Relewise/ProductCategorySearch?term=${term}&take=${take}&skip=${skip}`)
  .then(response => response.json())
  .then(data => {
    relatedProductGroups.value = data
  })
}


onBeforeMount(() => {
  getTranslations()
})


watch(query, debounce((value: string) => {
  // Whilst query is above 2 characters, clear and search, if under clear
  if (value.length > 2) {
    products.value = []
    search()
    getRelatedProductGroups(value)
  } else {
    products.value = []
    relatedProductGroups.value = []
    noResults.value = ''
  }
}, 225))

// If modal is opened, append the no-scroll class to the html element
watch(showModal, (value) => {
  calculateModalPosition()

  // The first time the modal is opened, get the popular groups and terms
  if (value && popularGroups.value.length === 0) {
    getPopularGroups()
    getPopularTerms()
  }

  if (value) {
    document.documentElement.classList.add('no-scroll', 'modal-open')
  } else {
    document.documentElement.classList.remove('no-scroll', 'modal-open')
    searchInput.value.blur()
  }
})

</script>

<template>
  <form @submit.prevent="goToSearchPage" class="tw-relative tw-flex lg:tw-w-full" :class="showModal ? 'tw-z-50 tw-w-[80vw]' : ''">
    <font-awesome-icon icon="magnifying-glass" class="tw-flex tw-items-center tw-pl-3 tw-absolute tw-top-4" />
    <input
        type="text"
        class="tw-w-full tw-border-2 tw-border-e-0 tw-border-black-900/10 tw-h-12 tw-pl-10 focus:tw-border-black-900 focus:tw-outline-none"
        ref="searchInput"
        @focus="showModal = true"
        placeholder="Find sofaer, senge og meget mere"
        v-model="query"
    >
    <button type="submit" class="search-button tw-bg-black-900 tw-text-white-900 tw-py-2 tw-px-4">
      Søg
    </button>
  </form>

  <SearchModal :show="showModal" :loading="loading" @close="showModal = false">
    <template #search>
    </template>
    <template #popular>
      <template v-if="relatedProductGroups.length > 0">
        <span class="tw-flex tw-font-bold tw-mb-2">Kategorier</span>
        <ul class="tw-p-0 tw-list-none tw-border-t">
          <li v-for="group in relatedProductGroups" :key="group.key">
            <a :href="group.Link" class="tw-flex tw-py-2 tw-border-b tw-cursor-pointer tw-text-black-900 hover:tw-border-black-900">{{ group.Name }}</a>
          </li>
        </ul>
      </template>
      <template v-else-if="popularTerms.length > 0">
        <span class="tw-flex tw-font-bold tw-mb-2">Populære søgninger</span>
        <ul class="tw-p-0 tw-list-none tw-border-t">
          <li v-for="term in popularTerms" :key="term.key">
            <a :href="term.Link" class="tw-flex tw-py-2 tw-border-b tw-cursor-pointer tw-text-black-900 hover:tw-border-black-900">{{ term.Term }}</a>
          </li>
        </ul>
      </template>
    </template>
    <template #content>
      <div v-if="products.length > 0" class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4">
        <ProductCard v-for="product in products" :product="product" :product-translations="productTranslations" :key="product.key" />
      </div>
      <div v-else-if="noResults" v-html="noResults">
      </div>
      <div v-else class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
        <div v-for="group in popularGroups" :key="group.key">
          <a :href="group.Link" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-3 tw-h-40 tw-bg-white-900 hover:tw-no-underline">
            <img :src="group.Image" class="tw-object-contain tw-h-20" :alt="group.Title" />
            <span class="tw-text-black-900 tw-text-center tw-mt-2">{{ group.Name }}</span>
          </a>
        </div>
      </div>
      <div v-if="products.length > 8" class="tw-flex tw-align-center tw-justify-center">
        <a :href="`/Default.aspx?ID=${props.searchResultsPage}&q=${query}`" class="tw-mt-8 btn brand-black btn-big transition tw-py-2 tw-bg-black-900 tw-text-white-900 hover:tw-no-underline">
          Se alle resultater
        </a>
      </div>
    </template>
  </SearchModal>
</template>

