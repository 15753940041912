import * as ko from 'knockout';
import $ from 'jquery';

export default class PreviewVariant {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var variantImage = value.variantImage;
        var currentImage = value.currentImage;
        var originalImage = value.originalImage;
        var link = value.link;

        $(element).hover(function () {
            $(currentImage).attr("src", variantImage);
        }, function () {
            $(currentImage).attr("src", originalImage);
        });

        $(element).click(function () {
            event.stopPropagation();
            event.preventDefault();
            window.location.href = link;
        });

    }
}
ko.bindingHandlers['previewVariant'] = new PreviewVariant();
