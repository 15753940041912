import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faPen, faPlusCircle, faMinusCircle, faPlus, faMinus, faAngleRight, faChevronDown, faChevronLeft, faChevronRight, faSpinner, faTimes, faTimesCircle, faCircleXmark, faPlay, faPlayCircle, faInfoCircle, faCircleNotch, faCheck, faCheckCircle, faMagnifyingGlass, faSearch, faClipboard, faBookOpen, faCalendar, faTruck, faPiggyBank, faTruckFast, faCartShopping, faShop } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faPinterest, faYoutube, faInstagram  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebook as any, faPinterest as any, faYoutube as any, faInstagram as any, faArrowLeft, faPen, faPlusCircle, faMinusCircle, faPlus, faMinus, faAngleRight, faChevronDown, faChevronLeft, faChevronRight, faSpinner, faTimes, faTimesCircle, faCircleXmark, faPlay, faPlayCircle, faInfoCircle, faCircleNotch, faCheck, faCheckCircle, faMagnifyingGlass, faSearch, faClipboard, faBookOpen, faCalendar, faTruck, faPiggyBank, faTruckFast, faCartShopping, faShop)

dom.watch()

export default FontAwesomeIcon;
