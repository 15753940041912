<script setup>

import {onBeforeMount, onMounted, ref} from "vue";
import Price from "./Price.vue";
import Splash from "./Splash.vue";

const props = defineProps({
  product: {
    type: Object,
  },
  productTranslations: {
    type: Object,
  },
})

// Map product data
let product = ref({
  title: props.product.Name,
  link: props.product.Link,
  image: props.product.Image,
  splashType: props.product.SplashType,
})

// Set splash class
let splashInfo = ref({
  splashType: 0,
  class: '',
  text: '',
});

// Combine the splash type and text in the object
const setSplash = () => {
  if (props.product.SplashType == 6) {
	splashInfo = {
		splashType: props.product.SplashType,
		class: 'jubileeprice',
		text: props.productTranslations.translationProductJubileePrice,
	}
  } else if (props.product.SplashType == 1) {
    splashInfo = {
      splashType: props.product.SplashType,
      class: 'offer',
      text: props.productTranslations.translationSplashProductOffer,
    }
  } else if (props.product.SplashType == 2) {
    splashInfo = {
      splashType: props.product.SplashType,
      class: 'new-item',
      text: props.productTranslations.translationSplashNewItem,
    }
  } else if (props.product.SplashType == 3) {
    splashInfo = {
      splashType: props.product.SplashType,
      class: 'low-price',
      text: props.productTranslations.translationSplashLowPrice,
    }
  } else if (props.product.SplashType == 4) {
    splashInfo = {
      splashType: props.product.SplashType,
      class: 'priceshape',
      text: props.productTranslations.translationSplashDailyPrice,
    }
  } else if (props.product.SplashType == 5) {
    splashInfo = {
      splashType: props.product.SplashType,
      class: 'combo',
      text: props.productTranslations.translationSplashCombinationOffer,
    }
  }
}

// Create price object
let priceObject = ref({
  beforePrice: props.product.BeforePrice,
  price: props.product.Price,
  saving: props.product.Saving,
  dailyPriceTextColor: props.product.DailyPriceTextColor,
  dailyPriceBackgroundColor: props.product.DailyPriceBackgroundColor,
  variantCombinations: props.product.VariantCombinations,
  translationPriceBefore: props.productTranslations.translationPriceBefore,
  translationPriceSaving: props.productTranslations.translationPriceSaving,
})

onBeforeMount(() => {
  setSplash();
})

</script>

<template>
  <div class="tw-flex tw-flex-col tw-justify-center tw-rounded-lg tw-bg-white-900 hover:tw-no-underline tw-p-3">
    <a :href="product.link"
       class="tw-flex tw-justify-start tw-align-center tw-h-auto tw-mb-3 tw-relative">
      <img :src="product.image" class="tw-object-contain tw-w-full tw-aspect-[4/3]" :alt="product.title" loading="lazy" />
      <Splash :splash="splashInfo" :splash-text-color="props.product.dailyPriceTextColor" :splash-bg-color="props.product.dailyPriceBackgroundColor" />
    </a>
    <a :href="product.link" class="tw-text-black-900 hover:tw-text-black-900">
      <h3 class="fs0">{{ product.title }}</h3>
    </a>
    <Price :price="priceObject" :splash="splashInfo" :product-link="product.link" />
  </div>
</template>
