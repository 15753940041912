<script setup lang="ts">
import { watch, onMounted, onBeforeUnmount } from 'vue'
import FontAwesomeIcon from '../../ts/fontawesome-icons.ts'

const props = defineProps({
  show: Boolean,
  loading: Boolean,
  exit: {
    type: String,
    default: 'Luk'
  }
})
const emit = defineEmits(['close'])

function keydownHandler(event: { key: string; }) {
  if(!props.show) return;

  if (event.key === "Escape") {
    emit('close')
  }
}

// When modal is opened, append the no-scroll class to the html element
watch(props.show, (value) => {
  if (value) {
    document.documentElement.classList.add('no-scroll', 'modal-open')
  } else {
    document.documentElement.classList.remove('no-scroll', 'modal-open')
  }
})


onMounted(() => {
  // Listen for keydown events
  window.addEventListener('keydown', keydownHandler)
})

onBeforeUnmount(() => {
  // Remove keydown event listener
  window.removeEventListener('keydown', keydownHandler)
});

</script>

<template>
  <div
      v-show="show"
      ref="modal"
      class="tw-items-center tw-overflow-hidden tw-z-40 tw-fixed tw-inset-0"
      :class="{ 'tw-flex': show, hidden: !show }"
      role="dialog"
      :aria-hidden="show ? 'false' : 'true'"
      aria-labelledby="popup-headline"
      aria-describedby="popup-description"
  >
    <div
        class="tw-absolute tw-inset-0 tw-bg-black-900 tw-opacity-50"
        aria-hidden="true"
        @click="$emit('close')"
    ></div>
    <div
        id="js-search-modal"
        class="tw-m-auto tw-min-w-[50vw] lg:tw-w-[1250px] tw-bg-white-900 tw-absolute tw-inset-x-0"
    >
      <div class="tw-absolute tw-right-4 tw-top-6 lg:tw-right-5 lg:tw-top-5">
        <button
            type="button"
            class="tw-p-3 hover:tw-underline"
            @click="$emit('close')"
        >
          <span class="tw-mr-2 tw-hidden lg:tw-inline-flex">{{ exit }}</span>
          <font-awesome-icon icon="close" />
          <span class="tw-sr-only">{{ exit }}</span>
        </button>
      </div>

      <div class="tw-flex tw-flex-col lg:tw-flex-row">
        <div class="tw-flex tw-bg-beige-900 lg:tw-bg-white-900 tw-w-full tw-h-[80px] lg:tw-hidden"></div>
          <aside class="tw-p-8 tw-w-[24rem] tw-bg-white-900 tw-overflow-y-scroll no-scrollbar tw-hidden lg:tw-block">
            <slot name="popular"></slot>
          </aside>

          <div class="tw-min-h-[40rem] tw-max-h-[90vh] lg:tw-max-h-[90vh] tw-flex-1 tw-p-4 tw-pb-20 lg:tw-p-8 lg:tw-pt-24 lg:tw-pb-8 tw-bg-beige-900 tw-overflow-y-scroll no-scrollbar">
            <div v-if="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
              <font-awesome-icon icon="circle-notch" class="tw-animate-spin tw-text-4xl tw-text-gray-900" />
              <span class="tw-mt-4 tw-text-gray-900">Søger...</span>
            </div>
            <div v-else>
              <slot name="content">
                Search results
              </slot>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>

</style>
