<template>
    <div
        v-show="show"
        ref="modal"
        class="tw-items-center tw-overflow-hidden tw-z-40 tw-fixed tw-inset-0"
        :class="{ 'tw-flex': show, hidden: !show }"
        role="dialog"
        :aria-hidden="show ? 'false' : 'true'"
        aria-labelledby="popup-headline"
        aria-describedby="popup-description"
    >
      <div
          class="tw-absolute tw-inset-0 tw-bg-black-900 tw-opacity-50"
          aria-hidden="true"
          @click="$emit('close')"
      ></div>
      <div
          class="tw-m-auto tw-min-w-[50vw] lg:tw-w-[1250px] tw-min-h-[30rem] tw-max-h-[90vh] lg:tw-h[90%] tw-bg-white-900 tw-overflow-y-scroll tw-relative"
      >
        <div class="tw-flex tw-justify-between tw-items-center tw-p-5 lg:tw-px-10">
          <slot name="modal-title">
            Modal Title
          </slot>

          <button
              type="button"
              class="tw-p-3 hover:tw-underline"
              @click="$emit('close')"
          >
            <span class="tw-mr-2">{{ exit }}</span>
            <font-awesome-icon icon="close" />
            <span class="tw-sr-only">{{ exit }}</span>
          </button>
        </div>

        <div class="tw-p-10">
          <slot name="content">
            Modal Content
          </slot>
        </div>

        <div>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
</template>

<script setup>
import { watch, onMounted, onBeforeUnmount } from 'vue'
import FontAwesomeIcon from '../../ts/fontawesome-icons.ts'

const props = defineProps({
  show: Boolean,
  exit: {
    type: String,
    default: 'Luk'
  }
})
const emit = defineEmits(['close'])

function keydownHandler(event) {
  if(!props.show) return;

  if (event.key === "Escape") {
    emit('close')
  }
}

// When modal is opened, append the no-scroll class to the html element
watch(props.show, (value) => {
  if (value) {
    document.documentElement.classList.add('no-scroll', 'modal-open')
  } else {
    document.documentElement.classList.remove('no-scroll', 'modal-open')
  }
})


onMounted(() => {
  // Listen for keydown events
  window.addEventListener('keydown', keydownHandler)
})

onBeforeUnmount(() => {
  // Remove keydown event listener
  window.removeEventListener('keydown', keydownHandler)
});

</script>
