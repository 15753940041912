<script setup lang="ts">

import { ref, onMounted } from "vue";

const props = defineProps({
  splash: {
    type: Object,
  },
  splashTextColor: {
    type: String,
  },
  splashBackgroundColor: {
    type: String,
  },
})

onMounted(() => {
})

</script>

<template>
  <div
      v-if="props.splash.splashType != 0"
      class="splash splash--on-top tw-p-1 product-card-splash"
      :class="props.splash.class"
      :style="props.splashBackgroundColor ? `background-color: ${props.splashBackgroundColor};` : ''"
  >
    <p class="m-0"
      :style="props.splashTextColor ? `color: ${props.splashTextColor};` : ''"
    >
      {{ props.splash.text }}
    </p>
  </div>
</template>

<style scoped>

</style>
