<script setup>
import { onMounted, ref, watch } from 'vue'
import Modal from './Modal.vue'

const props = defineProps({
  linkText: {
    type: String,
      default: 'Show modal'
  },
  modalContent: {
    type: String,
    default: 'Modal Test',
    required: true
  }
})

let showModal = ref(false)
let title = ref('')
let content = ref('')

// Get content from the API endpoint that's set through the modalContent prop
async function getContent() {
  await fetch(props.modalContent)
  .then((response) => response.json())
  .then((data) => {
    // Check that there are fields present, and that one of the objects has a SystemName of "Title", and a non-empty "Value".
    if (data.Item.Fields.length > 0 && data.Item.Fields.some((item) => item.SystemName === 'Title' && item.Value !== '')) {
      title.value = data.Item.Fields.find((item) => item.SystemName === 'Title').Value
    } else {
      title.value = data.Name
    }

    if (data.Item.Fields.length > 0 && data.Item.Fields.some((item) => item.SystemName === 'Desctiption' && item.Value !== '')) {
      content.value = data.Item.Fields.find((item) => item.SystemName === 'Desctiption').Value
    } else {
      content.value = data.Text
    }
  })
}

onMounted(() => {
  getContent()
})

// If modal is opened, append the no-scroll class to the html element
watch(showModal, (value) => {
  if (value) {
    document.documentElement.classList.add('no-scroll', 'modal-open')
  } else {
    document.documentElement.classList.remove('no-scroll', 'modal-open')
  }
})
</script>

<template>
  <a href="#" class="tw-opacity-50" @click.prevent="showModal = true">{{ linkText }}</a>

  <Modal :show="showModal" @close="showModal = false">
    <template #modal-title>
      {{  title  }}
    </template>
    <template #content>
      <div v-html="content"></div>
    </template>
  </Modal>
</template>
