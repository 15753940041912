﻿import * as ko from 'knockout';

export default class NewsletterSubscribe {

    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {

        // On checkout page
        const subscribeCheckbox = <HTMLInputElement>document.getElementById('newsletterCheckbox');
        if(subscribeCheckbox) {
            let orderSubmitted = false;

            subscribeCheckbox.addEventListener('change', () => {
                const orderForm = <HTMLFormElement>document.getElementById('ordersubmit');
                const orderFormSubmit = <HTMLButtonElement>orderForm.querySelector('input[type="submit"]');

                // Get input data from order form, in order to map correctly with MailChimp's merge tags, without changing their names
                const email = <HTMLInputElement>document.getElementById('EcomOrderCustomerEmail');
                const name = <HTMLInputElement>document.getElementById('EcomOrderCustomerName');
                const zip = <HTMLInputElement>document.getElementById('EcomOrderCustomerZip');
                const city = <HTMLInputElement>document.getElementById('EcomOrderCustomerCity');
                const signupLocation = window.location.href;

                // Split name after first space to get first and last name
                const nameSplit = name.value.split(' ');
                const [firstName, lastName] = nameSplit;

                if (subscribeCheckbox.checked) {
                    const formData = new FormData();
                    // Map data to corresponding merge tags
                    const data = {
                        'EMAIL': email.value,
                        'FNAME': firstName,
                        'LNAME': lastName,
                        'ZIP': zip.value,
                        'CITY': city.value,
                        'SIGNUP': signupLocation,
                        'GDPR': 'Accepteret'
                    };
                    for (const key in data) {
                        if (data.hasOwnProperty(key)) {
                            formData.append(key, data[key]);
                        }
                    }

                    // Ignored, because TS doesn't accept formData as a string, because it can have a File object, but works as it should
                    // More info: https://github.com/Microsoft/TypeScript/issues/30584
                    // @ts-ignore
                    const formDataString = new URLSearchParams(formData).toString();

                    // Run subscribeCustomer() when orderForm is submitted
                    orderForm.addEventListener('submit', (e) => {
                        if (orderSubmitted == false) {
                            e.preventDefault();
                        }
                        subscribeCustomer(formDataString).then(res => {
                            console.log(res.json());
                        }).then(() => {
                            if (orderSubmitted == false) {
                                orderSubmitted = true;
                                orderFormSubmit.click();
                            }
                        });
                    });
                }
            });
        }

        // On newsletter page or globally
        const termsofuseCheckbox = <HTMLFormElement>document.getElementById('termsofuse')
        if (termsofuseCheckbox) {
            let newsletterSignupForms = <NodeListOf<HTMLFormElement>>document.querySelectorAll('.js-newsletter-signup');
            let newsletterSignupFormsArray = Array.from(newsletterSignupForms);

            newsletterSignupFormsArray.forEach((form: HTMLFormElement) => {
                // On submit
                form.addEventListener('submit', (e) => {
                    e.preventDefault();
                    console.log('submittibg')
                    // Get input data from order form, in order to map correctly with MailChimp's merge tags, without changing their names
                    const email = <HTMLInputElement>form.querySelector('#newsletterSignupEmail');
                    const name = <HTMLInputElement>form.querySelector('#newsletterSignupName');
                    const signupLocation = window.location.href;

                    // Split name after first space to get first and last name
                    let firstName;
                    let lastName;
                    if (name) {
                        [firstName, lastName] = name.value.split(' ');
                    }

                    const formData = new FormData();
                    // Map data to corresponding merge tags
                    const data = {
                        'EMAIL': email.value,
                        'FNAME': firstName,
                        'LNAME': lastName,
                        'SIGNUP': signupLocation,
                        'GDPR': 'Accepteret'
                    };
                    for (const key in data) {
                        if (data.hasOwnProperty(key)) {
                            formData.append(key, data[key]);
                        }
                    }

                    // Ignored, because TS doesn't accept formData as a string, because it can have a File object, but works as it should
                    // More info: https://github.com/Microsoft/TypeScript/issues/30584
                    // @ts-ignore
                    const formDataString = new URLSearchParams(formData).toString();

                    // run subscribeCustomer with formDataString then redirect to success page
                    subscribeCustomer(formDataString).then(res => {
                        const newsletterMessage = <HTMLDivElement>form.querySelector('#newsletterMessage');
                        if (newsletterMessage) {
                            newsletterMessage.classList.remove('d-none');
                        }
                    });
                });
            });
        }

        async function subscribeCustomer(formDataString: string) {
            // Parameters `u` and `id` are grabbed from MailChimp's form creator, and is unique per audience
            return await fetch(`https://mobler.us14.list-manage.com/subscribe/post-json?u=9d6507ce12e46680cfb3e8d08&id=75c250b3ce&c=&${formDataString}`, {
                method: 'GET',
                mode: 'no-cors',
            });
        }
    }
}
ko.bindingHandlers['newsletterSubscribe'] = new NewsletterSubscribe();
