import * as ko from 'knockout';
import $ from 'jquery';

declare let Cookiebot: any;

export default class YoutubeVideo {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        // Initialize the iframe api first
        if (Cookiebot.consent.marketing) {
            let tag = document.createElement('script');

            tag.src = "https://www.youtube.com/iframe_api";
            let firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        // Then start processing the binding
        let value = valueAccessor();
        let videoId = value.videoId;
        let overlayId = value.overlayId;
        let playerId = value.playerId;
        let player = null;

        let initVideo = () => {
            new YT.Player('player', {
                height: '100%',
                width: '100%',
                videoId: videoId,
                events: {
                    'onReady': onPlayerReady
                }
            });
        }

        let onPlayerReady = (event) => {
            player = event.target;
            player.playVideo();
        }

        document.getElementById(overlayId).addEventListener('click', () => {
            $('#videoModal').modal('show');
            if (Cookiebot.consent.marketing) {
                initVideo();
            }
        });

        let onHide = () => {
            if(player) {
                player.pauseVideo();
            }
        }
        let onShow = () => {
            if(player) {
                player.playVideo();
            }
        }
        let videoModal = $('#videoModal');
        if(videoModal.attr("modaleventlistenersadded") != "true") {
            videoModal.attr("modaleventlistenersadded", "true");
            /* must use jQuery to catch 'show.bs.modal' / 'hide.bs.modal' event, because bootstrap uses .trigger - https://stackoverflow.com/questions/24211185/twitter-bootstrap-why-do-modal-events-work-in-jquery-but-not-in-pure-js */
            videoModal.on('show.bs.modal', () => {
                onShow();
            });
            videoModal.on('hide.bs.modal', () => {
                onHide();
            });
        }

        $("#cookierenew-video").on("click", function() {
            $("#videoModal").modal("toggle");
        })
    }
}
ko.bindingHandlers['youtubeVideo'] = new YoutubeVideo();
