import * as ko from 'knockout';
import $ from 'jquery';

export default class Cookies {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var urlParams = 'utm_medium';
        var url = window.location.href;


        var getQueryString = function ( field, url ) {
            var href = url ? url : window.location.href;
            var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
            var string = reg.exec(href);
            return string ? string[1] : null;
        };

        var getCookie = function (name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        };

        var createPartnerAdsCookie = function () {
            var date = new Date();
            date.setTime(date.getTime() + (3456000000));
            var expires = "; expires=" + date;
            document.cookie = "PartnerAds" + "=" + "true" + expires + "; path=/";
        };

        //$(document).ready(function(){
            //check to see if user is reffered from partner-ads
            //if(getQueryString(urlParams, url) == 'Partner-Ads'){
                //check to see if it's a returning user
                //if (getCookie('PartnerAds') !== 'true'){
                    //createPartnerAdsCookie();
                //};
            //};
        //});
    }
}
ko.bindingHandlers['Cookies'] = new Cookies();
