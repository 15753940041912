import * as ko from 'knockout';
import $ from 'jquery';
import ProductListViewModel from "../ViewModels/ProductListViewModel";

export default class LoadMoreProducts {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        const value = valueAccessor();
        let currentPage = value.currentPage;
        let totalPages = value.totalPages;
        const queryString = value.queryString;
        const autoload = new URLSearchParams("" + queryString).get("autoload");
        const loadingInformation = value.loadingInformation;
        const filterForm = document.getElementById('filters-form');
        let loadingProducts = false;
        const loadMoreButton = document.getElementById('load-more-products');
        let loadMoreButtonTriggered = false;
        let localStorage = window.localStorage.getItem("#js-product-list");
        let localStorageObj = localStorage ? JSON.parse(window.localStorage.getItem("#js-product-list")) : null;
        let runGetProductsImmediately = false;
        let userTraveledBackwardsOrForwards = (window.performance.getEntriesByType("navigation")[0] as any).type === "back_forward" || performance.navigation.type === 2;

        let advertisementItemsContainer = document.getElementById('js-advertisement-items-container');
        let hasAdvertisementItems: boolean = document.getElementById('js-advertisement-items-container') !== null;

        if(localStorage && localStorageObj.location === location.href && userTraveledBackwardsOrForwards) {
            // render html from local storage
            if (hasAdvertisementItems) {
                advertisementItemsContainer.remove();
            }
            document.getElementById("js-product-list").outerHTML = localStorageObj.outerHTML;
            currentPage = document.getElementById("js-product-list").getAttribute("currentpage");
            loadMoreButtonTriggered = true;
            runGetProductsImmediately = true;
        } else {
            // render fresh html
            window.localStorage.setItem("#js-product-list", "");
            loadMoreButton.addEventListener('click', (e) => {
                e.preventDefault();
                loadMoreButtonTriggered = true
                currentPage = 2
                loadMoreButton.classList.add('unimportant-hidden')
                getProducts(false, queryString)
            });
        }
        getProductListElements()

        // Get products as array
        function getProductListElements() {
            const productListItemElements = document.getElementsByClassName('js-product-list-item');
            let productListItems: Element[]
            if (productListItemElements) {
                productListItems = Array.from(productListItemElements)
            }

            if (hasAdvertisementItems) {
                insertAdvertisementItems(productListItems)
            }
        }

        document.addEventListener('scroll', () => {
            if (!loadingProducts && elementInViewport(element) && loadMoreButtonTriggered && currentPage <= totalPages) {
                getProducts(false, queryString)
            }
        })

        if (filterForm) {
            filterForm.addEventListener('change', () => {
                currentPage = 1;
                getProducts(true, '');
            })
        }

        function insertAdvertisementItems(productListItems: Element[]) {
            // Grab all advertisement items
            let advertisementItemsElements = document.querySelectorAll(".js-product-list-outer-container");
            if(advertisementItemsElements.length > 0) {
                let advertisementItems: Element[]
                advertisementItemsElements.forEach((ele, index) => {
                    let advertisementItemsElements = ele.querySelectorAll('.js-advertisement-item');
                    if (advertisementItemsElements) {
                        advertisementItems = Array.from(advertisementItemsElements);
                        // sort array by attribute data-item-position, so it's always correct in the DOM
                        advertisementItems.sort((a, b) => {
                            // return numeric values
                            return +a.getAttribute('data-item-position') - +b.getAttribute('data-item-position');
                        })
                        advertisementItems.forEach(item => {
                            // hide all advertisement items initially
                            item.classList.add('important-hidden');
                        })
                    }
                });

                productListItems.forEach((item: Element, index: number) => {
                    // for every 6th item, insert an advertisement item
                    if (index % 6 === 0) {
                        const advertisementItem = advertisementItems[index / 6 - 1]
                        if (advertisementItem) {
                            advertisementItem.classList.remove('important-hidden');
                            item.parentNode.insertBefore(advertisementItem, item);
                        }
                    }
                });
            }
        }

        const getProducts = function (filterRequest: boolean = false, queryString: string = '') {
            loadingProducts = true;
            loadingInformation.active(true);

            // Build the URL based on available parameters
            if (queryString !== '') {
                queryString = '&' + queryString
            }
            const filterContent = $('.js-filters-form').serialize();
            const url = '/1stwebsystem/ajaxproducts?pagenum=' + currentPage + queryString + "&" + filterContent;

            $.get(url, function (data) {
                const loadMoreTrigger = document.getElementById('loadMoreTrigger')

                if (filterRequest) {
                    var url = window.location.href.split('?')[0];
                    var newUrl = new URL(url + '?' + filterContent);
                    newUrl.searchParams.delete('GroupId');
                    history.pushState(null, '', newUrl.pathname + newUrl.search);
                    $('.js-product-list-item').remove();
                    totalPages = $(data).find('#js-ajax-result').data('pages');
                    if(totalPages < 2) {
                        loadMoreTrigger.classList.add("d-none");
                        loadMoreTrigger.classList.remove("d-flex");
                    } else {
                        loadMoreTrigger.classList.add("d-flex");
                        loadMoreTrigger.classList.remove("d-none");
                    }
                    $('.js-productlist-blogs').remove();
                    $('.js-filters-form').remove();
                    $('.js-filters-area').append($(data).find('.js-filters-result').html());
                    $('.js-filters-area .js-filters-form').change(function () {
                        currentPage = 1;
                        getProducts(true, '');
                    })
                }
                // Create results anchor
                let newResultsAnchor = document.createElement('div')
                newResultsAnchor.setAttribute('id', `results-${currentPage}`);
                // Grab the whole div as a string
                let newResultsAnchorAsString = newResultsAnchor.outerHTML;

                // Get the results data and insert the anchor and results before the load more trigger
                const newResult = $(data).find('#js-ajax-result').html();
                loadMoreTrigger.insertAdjacentHTML('beforebegin', newResultsAnchorAsString);
                loadMoreTrigger.insertAdjacentHTML('beforebegin', newResult);

                // Grab all product items after initial product load
                getProductListElements()

                var childBindingContext = bindingContext.createChildContext(viewModel);
                ko.applyBindingsToDescendants(childBindingContext, document.getElementById(`results-${currentPage}`));
                if (filterRequest) {
                    currentPage = 2;
                }
                else {
                    currentPage++;
                }
                loadingProducts = false;
                loadingInformation.active(false);
                let currentViewModel = viewModel as ProductListViewModel;
                currentViewModel.GaPushDataLayerForLoadMoreProducts();

                document.getElementById("js-product-list").setAttribute("currentpage", currentPage);
                window.localStorage.setItem('#js-product-list', JSON.stringify({
                    outerHTML: document.getElementById("js-product-list").outerHTML,
                    location: location.href
                }));
            });
        }

        if(runGetProductsImmediately) {
            getProducts(false, queryString);
        } else if (autoload) {
            loadMoreButton?.click();
        }
    }
}
ko.bindingHandlers['loadMoreProducts'] = new LoadMoreProducts();

function elementInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        (top + height) <= (window.pageYOffset + window.innerHeight) &&
        (left + width) <= (window.pageXOffset + window.innerWidth)
    );
}
