﻿import * as ko from 'knockout';
import {GA4EcommerceItem} from "../Models/GA4EcommerceItem";

export default class GAEcommerceItemAttributeRenderer {
    public init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        if (element !== null && valueAccessor() != null) {
            let gaItem = valueAccessor() as GA4EcommerceItem
            let props  = Object.entries(gaItem);
            for (let i = 0; i < props.length; i++) {
                let key = props[i][0];
                let val = props[i][1];
                if (val !== null) {
                    element.setAttribute('data-'+key, val);
                }
            }
        }
    }
}
ko.bindingHandlers['gaEcommerceItem'] = new GAEcommerceItemAttributeRenderer();
