// js modules
import 'bootstrap/dist/js/bootstrap.min.js';
import 'nouislider/distribute/nouislider.min.js';

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "select2/dist/css/select2.min.css";

// scss modules
import './scss/master.scss';

// web/vue components
import { createApp } from "vue";

// Automatically grab and register components
const modules = import.meta.glob('./vue/components/*.vue', { eager: true })

Object.entries(modules).forEach(([path, module]) => {
    // @ts-ignore
    const name = path.split('/').pop().split('.').shift().toLowerCase()

    // For each Vue component found, register it
    if (document.querySelector( name) === null) return

    var els = document.querySelectorAll( name);
    els.forEach((v,i,p) => {
        const props = datasetToObject(v);
        createApp(module.default,props).mount(v);
    })
})

// Parse the incoming data attributes, to correctly handle JSON as well as numbers and strings
function datasetToObject(element: Element) {
    const dataset = element.dataset;
    const obj = {};

    for (const key in dataset) {
        if (dataset.hasOwnProperty(key)) {
            const value = dataset[key];

            try {
                // Try parsing as JSON
                obj[key] = JSON.parse(value);
            } catch (error) {
                // Fallback to string or number
                obj[key] = isNaN(value) ? value : Number(value);
            }
        }
    }

    return obj;
}


// ts modules
import "./ts/Models";
import "./ts/CustomBindings";
import "./ts/ViewModels";



