<script setup>

import {ref, defineComponent, onMounted, onBeforeMount, onBeforeUnmount} from "vue";
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import FontAwesomeIcon from '../../ts/fontawesome-icons.ts';
import Cylindo from "../../ts/CustomBindings/Cylindo";

const props = defineProps({
	product: {
		type: Object,
	},
})

const mainOptions = {
	rewind: true,
	type: 'loop',
	perPage: 1,
	perMove: 1,
	gap: '1rem',
	pagination: false,
	arrows: true,
	drag: false,
	dragAngleThreshold: 0,
	classes: {
		prev: 'splide__arrow--prev bg-white',
		next: 'splide__arrow--next bg-white',
	}
};

const thumbsOptions = {
	type: 'slide',
	rewind: true,
	gap: '',
	pagination: false,
	fixedWidth: '100%',
	fixedHeight: '100px',
	focus: 'center',
	isNavigation: true,
	updateOnMove: true,
	direction: 'ttb',
	height: '100%',
	classes: {
		prev: 'splide__arrow--prev bg-white',
		next: 'splide__arrow--next bg-white',
	}
};

//ELEMENTS
let thumbssplideref = ref(null);
let mainsplideref = ref(null);
let productcarouselref = ref(null);
let productcarouselchildelementref = ref(null)

//CAROUSEL
let carouselProduct = ref({
	productName: props.product.productName,
	images: props.product.images,
	videos: props.product.videos,
	isUsingCylindoAssets: props.product.isUsingCylindoAssets,
	cylindoImages: props.product.cylindoImages,
	cylindoAccountId: props.product.cylindoAccountId,
	cylindoProductId: props.product.cylindoProductId,
	cylindoDimensionShot: props.product.cylindoDimensionShot,
	onTopSplatterText: props.product.onTopSplatterText,
	onTopSplatterType: props.product.onTopSplatterType
})

if (!window["co3VueProductCarousel"]) {
	window["co3VueProductCarousel"] = [carouselProduct];
} else {
	window["co3VueProductCarousel"].push(carouselProduct);
}

let carouselIsExpanded = ref(false);
let carouselHasPages = false;
let isMobileSize = false;
let hasVerticalNavigation = true;
let renderedThumbsOnce = false;
const isCylindoLoaded = (() => {
	return customElements.whenDefined( "cylindo-viewer" );
})();

const handleCarouselPadding = () => {
	if (isMobile.matches) {
		productcarouselref.value.style.padding = '1rem 1rem';
	} else {
		productcarouselref.value.style.padding = '4% 8%';
	}
}

const SetMobileRestrictions = () => {
	isMobileSize = true;
	CloseCarousel();
	Array.from(productcarouselref.value.querySelectorAll('.js-pointer-events-toggle')).forEach((element, index) => {
		element.classList.remove('tw-pointer-events-none');
	});
	RenderThumbs();
}

const RemoveMobileRestrictions = () => {
	isMobileSize = false;
	Array.from(productcarouselref.value.querySelectorAll('.js-pointer-events-toggle')).forEach((element, index) => {
		element.classList.add('tw-pointer-events-none');
	});
	RenderThumbs();
}

const ExpandCarousel = (e) => {
	if (e) {
		e.stopPropagation();
	}
	if (!isMobileSize) {
		productcarouselref.value.style.backgroundColor = 'rgba(0,0,0,0.5)';
		carouselIsExpanded.value = true;
		let maybeVideo = e.target.querySelector(".video-to-pause-on-move");
		let closeButton = productcarouselref.value.querySelector(".js-modal-closer");
		if (closeButton) {
			closeButton.classList.remove("tw-hidden");
		}
		if (maybeVideo) {
			PlayVideo(maybeVideo);
		}
		Array.from(productcarouselref.value.querySelectorAll('.js-pointer-events-toggle')).forEach((element, index) => {
			element.classList.remove('tw-pointer-events-none');
		});
		productcarouselref.value.style.zIndex = '40';
		productcarouselref.value.style.position = 'fixed';
		handleCarouselPadding();
		productcarouselchildelementref.value.style.border = "1px solid black";
		productcarouselchildelementref.value.style.padding = "3em 1.5em";
		//mainsplideref.value.go(mainsplideref.value.index);

		let splash = productcarouselref.value.querySelector(".product-card-splash");
		if (splash) {
			splash.style.display = 'none';
		}
	}

	mainsplideref.value.splide.event.emit('resize');
	thumbssplideref.value.splide.event.emit('resize');
}
const OpenVideo = (e) => {
	if (e) {
		e.stopPropagation();
	}
	if (videoIndex > 0) {
		mainsplideref.value.splide.go(videoIndex);
	}
}

const CloseCarousel = (e) => {
	if (e) {
		e.stopPropagation();
	}
	carouselIsExpanded.value = false;
	StopVideos();
	let closeButton = productcarouselref.value.querySelector(".js-modal-closer");
	if (closeButton) {
		closeButton.classList.add("tw-hidden");
	}
	Array.from(productcarouselref.value.querySelectorAll('.js-pointer-events-toggle')).forEach((element, index) => {
		element.classList.add('tw-pointer-events-none');
	});
	productcarouselref.value.style.zIndex = 'inherit';
	productcarouselref.value.style.position = 'inherit';
	productcarouselref.value.style.padding = '0';
	productcarouselchildelementref.value.style.border = "0";
	productcarouselchildelementref.value.style.padding = "0";

	let splash = productcarouselref.value.querySelector(".product-card-splash");
	if (splash) {
		splash.style.display = '';
	}

	mainsplideref.value.splide.event.emit('resize');
	thumbssplideref.value.splide.event.emit('resize');
}

const RenderVerticalThumbs = () => {
	console.log('rendering vertical thumbs');
	hasVerticalNavigation = true;
	thumbssplideref.value.splide.options = {
		direction: 'ttb',
		fixedWidth: '100%',
		fixedHeight: '20%',
	};
	mainsplideref.value.splide.options = {
		arrows: true,
	};
	thumbssplideref.value.root.classList.remove("tw-w-full");
	thumbssplideref.value.root.classList.remove("tw-h-1/6");

	thumbssplideref.value.root.classList.add("tw-h-full");
	thumbssplideref.value.root.classList.add("tw-w-1/6");
	thumbssplideref.value.root.classList.add("tw-absolute");

	mainsplideref.value.root.classList.add('tw-w-5/6');
	mainsplideref.value.root.classList.add('tw-h-full');
	mainsplideref.value.root.classList.remove('tw-w-full');
	mainsplideref.value.root.classList.remove('tw-h-5/6');

	let container = productcarouselchildelementref.value.querySelector('.js-splide-direction-container');
	container.classList.remove("tw-flex");
	container.classList.remove("tw-flex-col-reverse");

	mainsplideref.value.splide.event.emit('resize');
	thumbssplideref.value.splide.event.emit('resize');
	renderedThumbsOnce = true;
}

const RenderHorisontalThumbs = () => {
	console.log('rendering horisontal thumbs');
	hasVerticalNavigation = false;
	thumbssplideref.value.splide.options = {
		direction: 'ltr',
		fixedWidth: '25%',
		fixedHeight: '100%',
	};
	mainsplideref.value.splide.options = {
		arrows: false,
	};

	thumbssplideref.value.root.classList.add("tw-w-full");
	thumbssplideref.value.root.classList.add("tw-h-1/6");

	thumbssplideref.value.root.classList.remove("tw-h-full");
	thumbssplideref.value.root.classList.remove("tw-w-1/6");
	thumbssplideref.value.root.classList.remove("tw-absolute");

	mainsplideref.value.root.classList.remove('tw-w-5/6')
	mainsplideref.value.root.classList.remove('tw-h-full');
	mainsplideref.value.root.classList.add('tw-w-full');
	mainsplideref.value.root.classList.add('tw-h-5/6');

	let container = productcarouselchildelementref.value.querySelector('.js-splide-direction-container');
	container.classList.add("tw-flex");
	container.classList.add("tw-flex-col-reverse");

	mainsplideref.value.splide.event.emit('resize');
	thumbssplideref.value.splide.event.emit('resize');
	renderedThumbsOnce = true;
}

const RenderThumbs = () => {
	console.log(`rendering thumbs. Has pages: ${carouselHasPages}`);
	if (carouselHasPages) {
		if (productcarouselref.value.clientWidth > productcarouselref.value.clientHeight) {
			if (hasVerticalNavigation === false || renderedThumbsOnce === false) {
				RenderVerticalThumbs();
			}
		} else if (hasVerticalNavigation === true || renderedThumbsOnce === false) {
			RenderHorisontalThumbs();
		}
	}
}

const StopVideos = () => {
	Array.from(mainsplideref.value.root.querySelectorAll("iframe.video-to-pause-on-move")).forEach((element, index) => {
		element.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
	});
}

const PlayVideo = (element) => {
	element.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
}

const StopPropagation = (e) => {
	e.stopPropagation();
}
const keydownHandler = (e) => {
	if (event.key === "Escape") {
		CloseCarousel(e);
	}
}

const checkSplideLength = () => {
	if (mainsplideref.value.splide.length <= mainsplideref.value.splide.options.perPage) {
		console.log('only 1 page');
		carouselHasPages = false;
		renderedThumbsOnce = false;
		//HIDE THUMBS IF ONLY 1 PAGE
		thumbssplideref.value.root.classList.remove("tw-block");
		thumbssplideref.value.root.classList.add("tw-hidden");

		//SET MAIN SPLIDE TO OCCUPY FULL AREA
		mainsplideref.value.root.classList.remove("tw-w-5/6", "tw-h-5/6");
		mainsplideref.value.root.classList.add("tw-h-full", "tw-w-full");

		mainsplideref.value.splide.options = {
			arrows: false,
		};
	} else {
		console.log('more than 1 page');
		carouselHasPages = true;
		//SHOW THUMBS
		thumbssplideref.value.root.classList.add("tw-block", "tw-mt-[48px]", "md:tw-mt-0");
		thumbssplideref.value.root.classList.remove("tw-hidden");

		RenderThumbs();

		mainsplideref.value.splide.options = {
			arrows: true,
		};
	}
}

// Create a condition that targets viewports at least 768px wide
const isMobile = window.matchMedia('(max-width: 1023px)');

const handleMobileDesktopChange = (e) => {
	console.log('checking mobile/desktop');
	// Check if the media query is true
	if (carouselIsExpanded.value) {
		handleCarouselPadding();
	}
	if (e.matches) {
		SetMobileRestrictions();
	} else {
		RemoveMobileRestrictions();
	}
}

// Register event listener
isMobile.addEventListener('change', handleMobileDesktopChange);
window.addEventListener("resize", (e) => {
	RenderThumbs();
});

let videoIndex = ref(0);

onBeforeUnmount(() => {
	window.removeEventListener('keydown', keydownHandler);
});


onBeforeMount(()  => {
  // Wait IsCylindoReady to be true, before initialising the carousel


});

onMounted(async () => {
	if(Cylindo.shouldLoad()){
		await Cylindo.isReady().then(() =>{
			console.log('Cylindo is ready');
		});
	}


	window.addEventListener('keydown', keydownHandler);

	Array.from(productcarouselref.value.querySelectorAll('.splide__track')).forEach((element, index) => {
		element.classList.add('tw-h-full');
	});

	if (mainsplideref.value) {
		if (thumbssplideref.value.splide) {
			mainsplideref.value.sync(thumbssplideref.value.splide);
		}

		mainsplideref.value.splide.on('move', () => {
			StopVideos();
		});

		mainsplideref.value.splide.on('refresh', () => {
			console.log('resizing, because of refresh of mainsplide');
			checkSplideLength();
			handleMobileDesktopChange(isMobile);
			mainsplideref.value.splide.event.emit('resize');
			thumbssplideref.value.splide.event.emit('resize');
		});

		// Initial check
		checkSplideLength();
		handleMobileDesktopChange(isMobile);
	}



	// Get index of where the video is
	if (mainsplideref.value.splide) {

		// Wait for cylindoImages to be loaded
		await Cylindo.isReady().then(() =>{
			checkSplideLength();
			console.log('Cylindo Images loaded');
		})


		videoIndex = mainsplideref.value.splide.Components.Elements.slides.findIndex((slide) => {
			return slide.querySelector('iframe');
		});
	}

});

const renderCylindoElement = ( data, subComponentTagName, options ) => {
	let subComponentDomElement = null;
	const mainComponentOptions = ['id', 'controls'];

	if ( subComponentTagName !== null ) {
		subComponentDomElement = document.createElement( subComponentTagName );
		Object.keys( options ).forEach( ( optionKey ) => {
			if ( mainComponentOptions.includes( optionKey ) === false ) {
				subComponentDomElement.setAttribute( optionKey, options[optionKey] );
			}
		} );
	}

	return `<cylindo-viewer
              id="${ options.id ?? '' }"
              customer-id="${ data.cylindoAccountId }"
              code="${ data.cylindoProductId }"
              controls="${ options.controls ?? '' }">
              ${ subComponentDomElement ? subComponentDomElement.outerHTML : '' }
          </cylindo-viewer>`;

}

</script>

<template>
	<div class="product-splide-carousel-carousel tw-mb-12">

		<div class="tw-flex tw-relative tw-w-full tw-h-full tw-inset-0"
			 ref="productcarouselref" @click="CloseCarousel">
			<div class="tw-w-full tw-h-full tw-relative bg-white" @click="StopPropagation"
				 ref="productcarouselchildelementref">

				<div @click="CloseCarousel" style="z-index:50;margin:0.5em 1em;"
					 class="tw-absolute tw-right-0 tw-top-0 tw-hidden tw-p-1.5 tw-cursor-pointer js-modal-closer">
					<span class="tw-mr-2">Luk</span>
					<font-awesome-icon icon="close"/>
				</div>

				<div class="js-splide-direction-container tw-w-full tw-h-full tw-relative">

					<div v-if="carouselProduct.onTopSplatterText" class="splash splash--on-top p-1 product-card-splash"
						 :class="carouselProduct.onTopSplatterType">
						<p class="m-0" style="">{{ carouselProduct.onTopSplatterText }}</p>
					</div>

					<Splide class="tw-block tw-absolute tw-top-0 tw-bottom-0 mobile-thumb-height"
							:has-track="false"
							:options="thumbsOptions" ref="thumbssplideref">
						<SplideTrack>
							<SplideSlide v-if="carouselProduct.isUsingCylindoAssets === true"
										 class="js-cylindo-main-thumbnail-container-target-container">
								<div class="tw-w-full tw-h-full tw-flex tw-items-center"
									 v-html="renderCylindoElement(carouselProduct, 'cylindo-360-frame', {frame: 1})">
								</div>
							</SplideSlide>
							<SplideSlide v-if="carouselProduct.isUsingCylindoAssets === true && carouselProduct.cylindoDimensionShot "
										 class="js-cylindo-main-thumbnail-container-target-container">
								<div class="tw-w-full tw-h-full tw-flex tw-items-center"
									 v-html="renderCylindoElement(carouselProduct, 'cylindo-dimension-shot', {'dimension-code': carouselProduct.cylindoDimensionShot, unit: 'cm'})">
								</div>
							</SplideSlide>
							<SplideSlide v-if="carouselProduct.isUsingCylindoAssets === true"
										 v-for="image in carouselProduct.cylindoImages">
								<div class="tw-w-full tw-h-full tw-flex tw-items-center">
									<img class="tw-w-full tw-h-full tw-object-contain" width="400" height="400"
										 :src="image"
										 :alt="carouselProduct.productName">
								</div>
							</SplideSlide>
							<SplideSlide v-if="carouselProduct.isUsingCylindoAssets === false"
										 v-for="image in carouselProduct.images">
								<div class="tw-w-full tw-h-full tw-flex tw-items-center">
									<img class="tw-w-full tw-h-full tw-object-contain" width="400" height="400"
										 :src="image"
										 :alt="carouselProduct.productName">
								</div>
							</SplideSlide>
							<SplideSlide v-for="video in carouselProduct.videos">
								<div class="tw-w-full tw-h-full tw-flex tw-items-center">
									<img class="tw-w-full tw-h-full tw-object-contain" width="400" height="400"
										 :src="`https://i3.ytimg.com/vi/${video}/hqdefault.jpg`"
										 :alt="carouselProduct.productName"/>
								</div>
							</SplideSlide>
						</SplideTrack>
					</Splide>

					<Splide class="tw-ml-auto" :has-track="false" :options="mainOptions" ref="mainsplideref">
						<SplideTrack>
							<SplideSlide v-if="carouselProduct.isUsingCylindoAssets === true"
										 class="js-cylindo-main-container-target-container" @click="ExpandCarousel">
								<div
									class="tw-w-full tw-h-full tw-flex tw-items-center tw-pointer-events-none js-pointer-events-toggle"
									v-html="renderCylindoElement(carouselProduct, 'cylindo-360', {id: 'cylindo-main-container', controls: 'zoom'})">
								</div>
							</SplideSlide>
							<SplideSlide v-if="carouselProduct.isUsingCylindoAssets === true && carouselProduct.cylindoDimensionShot"
										 class="js-cylindo-main-container-target-container" v-on:click="ExpandCarousel">
								<div class="tw-w-full tw-h-full tw-flex tw-items-center tw-pointer-events-none js-pointer-events-toggle"
									 v-html="renderCylindoElement(carouselProduct, 'cylindo-dimension-shot', {'dimension-code': carouselProduct.cylindoDimensionShot, unit: 'cm'})">
								</div>
							</SplideSlide>
							<SplideSlide v-if="carouselProduct.isUsingCylindoAssets === true"
										 v-for="image in carouselProduct.cylindoImages" @click="ExpandCarousel"
										 data-type="cylindo">
								<div
									class="tw-w-full tw-h-full tw-flex tw-items-center tw-pointer-events-none js-pointer-events-toggle">
									<img class="tw-w-full tw-h-full tw-object-contain" width="1600" height="1000"
										 :src="image"
										 :alt="carouselProduct.productName">
								</div>
							</SplideSlide>
							<SplideSlide v-if="carouselProduct.isUsingCylindoAssets === false"
										 v-for="image in carouselProduct.images"
										 @click="ExpandCarousel">
								<div
									class="tw-w-full tw-h-full tw-flex tw-items-center tw-pointer-events-none js-pointer-events-toggle">
									<img class="tw-w-full tw-h-full tw-object-contain" width="1600" height="1000"
										 :src="image"
										 :alt="carouselProduct.productName">
								</div>
							</SplideSlide>
							<SplideSlide v-for="video in carouselProduct.videos" @click="ExpandCarousel">
								<div
									class="tw-w-full tw-h-full tw-flex tw-items-center tw-pointer-events-none js-pointer-events-toggle">
									<div style="padding-bottom: 56.25%;"
										 class="tw-w-full tw-h-0 tw-flex tw-items-center tw-pointer-events-none tw-relative js-pointer-events-toggle">
										<iframe style="position: absolute; top: 0; left: 0;"
												class="video-to-pause-on-move" width="100%"
												height="100%"
												:src="`https://www.youtube.com/embed/${video}?rel=0&controls=1&modestbranding=1&enablejsapi=1`"
												title="YouTube video player"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												allowfullscreen></iframe>
									</div>
								</div>
							</SplideSlide>
						</SplideTrack>

						<div class="tw-hidden lg:tw-flex tw-justify-center md:tw-justify-end tw-py-3 tw-gap-4">
<!--							<button class="hover:tw-underline" v-if="carouselProduct.videos.length > 0" @click="OpenVideo">-->
<!--								<font-awesome-icon icon="play"/>-->
<!--								<span class="tw-ml-2">Video</span>-->
<!--							</button>-->
							<button v-if="!carouselIsExpanded" class="hover:tw-underline" @click="ExpandCarousel">
								<font-awesome-icon icon="magnifying-glass"/>
								<span class="tw-ml-2">Zoom</span>
							</button>
						</div>

					</Splide>

				</div>
			</div>
		</div>

	</div>
</template>

