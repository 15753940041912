import * as ko from 'knockout';
import $ from 'jquery';
import * as noUiSlider from 'noUiSlider';

export default class NoUiSlider {
    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var startMin = value.startMin;
        var startMax = value.startMax;
        var rangeMin = 0;
        var rangeMax = value.rangeMax;
        var minDisplay = $(value.minDisplay);
        var maxDisplay = $(value.maxDisplay);
        var minInput = $('input[name=' + value.minInputName + ']');
        var maxInput = $('input[name=' + value.maxInputName + ']');

        var slider = element as noUiSlider.Instance;

        noUiSlider.create(slider, {
            start: [startMin, startMax],
            connect: true,
            step: 1,
            range: {
                'min': rangeMin,
                'max': rangeMax
            }
        });

        slider.noUiSlider.on('update', function () {
            var minValue = slider.noUiSlider.get()[0].split('.')[0];
            var maxValue = slider.noUiSlider.get()[1].split('.')[0];
            minDisplay.html(minValue);
            maxDisplay.html(maxValue);
            minInput.val(minValue);
            maxInput.val(maxValue);
        })

    }
}
ko.bindingHandlers['noUiSlider'] = new NoUiSlider();
